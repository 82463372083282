import {AJAX_LOADING_STATUS_ERROR, httpGet, httpPost} from "../../../library/http";
import type {Host} from "../types/hosts";
import {isDebugMode} from "../../../config";

export const getHosts = async (): Promise<Array<Host>|null> => {
    const response = await httpGet("hosts/get");

    return Array.isArray(response) ? response : null;
};

export const getHost = async (id): Promise<Host|null> => {
    const response = await httpGet(`hosts/get/${id}`);

    if(Array.isArray(response) && response.length) {
        return response[0];
    }

    return null;
};

export const deleteHost = async (id): Promise<Boolean> => {
    const response = await httpPost(`hosts/delete/${id}`);

    return response?.Status === "Success";
};

export const createHost = async (hostName, hostDescription, hostTypeId, license): Promise<String|null> => {
    const response = await httpPost("hosts/create", {
        hostName: hostName.toString(),
        hostDescription: hostDescription.toString(),
        hostTypeId: hostTypeId.toString(),
        license: license.toString(),
    });

    if(response?.Status === "Success") {
        //returns host ID
        return response.Response;
    }

    return null;
};