export const getDefaultPageTitle = (): string|null => {
    const meta = document.querySelector('meta[name="title"]');

    if(meta) {
        return meta.content;
    }

    return null;
};

export const setPageTitle = (title) => {
    document.title = `${title} | ${getDefaultPageTitle()}`;
};