import style from './Loading.module.css'
import ctx from "classnames";

const Loading = ({label = "Loading...", noStyle = false, labelStyle = null}: {
    label?: String,
    noStyle?: Boolean,
    labelStyle?: any,
}) => {

    return (
        <div className={ctx(style.wrapper, noStyle && style.wrapperNoStyle)}>
            <div className={style.spinner}>
                <div className="spinner-border text-info" />
            </div>
            {label.length > 0 && (
                <div className={ctx(style.label, labelStyle)}>{label}</div>
            )}
        </div>
    )
};

export default Loading;