import React, {useEffect, useState} from "react";
import style from "./SetNewPassword.module.css";
import {  useParams } from 'react-router-dom';
import {setPageTitle} from "../../../library/title";
import {showSuccessMessage, showWarningMessage} from "../../../library/messages";
import {updatePassword} from "../http";

const SetNewPassword = () => {
    const { token } = useParams();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

    const [hasPasswordBeenUpdated, setHasPasswordBeenUpdated] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(newPassword.length < 6) {
            showWarningMessage('Your password must be at least 6 characters.');
            return
        } else if (newPassword !== newPasswordConfirm) {
            showWarningMessage('The passwords do not match!');
            return
        }

        setIsSubmitting(true)
        const response = await updatePassword(token, newPassword);
        setIsSubmitting(false)

        if(response) {
            setHasPasswordBeenUpdated(true)

            showSuccessMessage('Your password was updated.')
        } else {
            showWarningMessage('There was a problem updating your password. Please, try again.')
        }
    }

    useEffect(() => {
        setPageTitle("Set new password");
    }, []);

    return (
        <div>
            <div className="container-fluid p-0">
                <div className="row g-0">
                    <div className="col-lg-4">
                        <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div className="w-100">
                                <div className="row justify-content-center">
                                    <div className="col-lg-9">
                                        <div>
                                            <div className="text-center">
                                                <div>
                                                    <a href="/" className="">
                                                        <img src={require("../../../assets/img/logo.png")} />
                                                        <span className={style.logoText}>QuantMonitor</span>
                                                    </a>
                                                </div>

                                                <h4 className="font-size-18 mt-4">Reset Password</h4>
                                                <p className="text-muted">Set a new password for your account</p>
                                            </div>

                                            <div className="p-2 mt-5">
                                                {hasPasswordBeenUpdated ? (
                                                    <div className={"alert alert-success"}>
                                                        Your password was updated.
                                                        <br />
                                                        You can now <a href={"/login"}>log in</a> to your account.
                                                    </div>
                                                ) : (
                                                    <form className="" action="/" method={"post"} onSubmit={handleSubmit}>
                                                        <div className="auth-form-group-custom mb-4">
                                                            <i className="ri-mail-line auti-custom-input-icon" />
                                                            <label>New password</label>
                                                            <input autoComplete={"new-password"} disabled={isSubmitting} value={newPassword} onChange={e => {
                                                                setNewPassword(e.target.value.trim())
                                                            }} type="password" required className="form-control" placeholder="Your new password..." />
                                                        </div>
                                                        <div className="auth-form-group-custom mb-4">
                                                            <i className="ri-mail-line auti-custom-input-icon" />
                                                            <label>Password confirm</label>
                                                            <input disabled={isSubmitting} value={newPasswordConfirm} onChange={e => {
                                                                setNewPasswordConfirm(e.target.value.trim())
                                                            }} type="password" required className="form-control" placeholder="Confirm the password..." />
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <button
                                                                disabled={isSubmitting}
                                                                className="btn btn-primary w-md waves-effect waves-light"
                                                                type="submit">Update password
                                                            </button>
                                                        </div>
                                                    </form>
                                                )}
                                            </div>

                                            <div className="mt-5 text-center">
                                                <p>
                                                    Already have an account? <a href="/" className="fw-medium text-primary">Log in</a>
                                                </p>
                                                <p>
                                                    <small>&copy; QuantMonitor.net</small>
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="authentication-bg">
                            <div className="bg-overlay" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SetNewPassword;