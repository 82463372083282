import style from './LoggedIn.module.css';
import TopBar from "./components/TopBar";
import Sidebar from "./components/Sidebar";
import MainContent from "./components/MainContent";
import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./Dashboard/Dashboard";
import Profile from "./Profile/Profile";
import Error404 from "../Error404/Error404";
import Notifications from "./Profile/Notifications";
import Subscription from "./Subscription/Subscription";
import {useState} from "react";

const LoggedIn = () => {
    const [license, setLicense] = useState(null);
    const [hosts, setHosts] = useState([]);

    return (
        <div id="layout-wrapper">
            <TopBar />
            <Sidebar license={license} />

            <MainContent>
                <Routes>
                    <Route path="/" element={<Dashboard license={license} setLicense={setLicense} hosts={hosts} setHosts={setHosts} />} />
                    <Route path="/subscription" title={"Subscription"} element={<Subscription />}/>
                    <Route path="/profile" title={"Profile"} element={<Profile />}/>
                    <Route path="/profile-notifications" title={"Notifications"} element={<Notifications />}/>

                    <Route
                        path="*"
                        element={<Error404 />}
                    />
                </Routes>
            </MainContent>
        </div>
    )
};

export default LoggedIn;