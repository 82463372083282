import {useContext, useEffect, useRef, useState} from 'react';
import style from './Subscription.module.css'

import {setPageTitle} from "../../../library/title";

//https://stackoverflow.com/a/819455/2880184
function resizeIFrameToFitContent( iFrame ) {
    //iFrame.width  = iFrame.contentWindow.document.body.scrollWidth;
    //iFrame.height = iFrame.contentWindow.document.body.scrollHeight;
}

const Subscription = () => {

    const iframeRef = useRef(null)

    useEffect(() => {
        setPageTitle("Subscription");

        resizeIFrameToFitContent(iframeRef.current)

    }, []);


    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Subscription</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href="/">QuantMonitor</a></li>
                                <li className="breadcrumb-item active">Subscription</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <iframe
                                ref={iframeRef}
                                className={style.iframe}
                                frameBorder={0}
                                src={"https://www.quantmonitor.net/download-subscribed-products/"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Subscription;