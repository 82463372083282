import {useState, useEffect} from "react";
import {
    updateAccessToken as setAccessTokenToStorage,
    getAccessToken as getAccessTokenToStorage,
    revokeAccessToken,
} from './token';

export const useGetAccessToken = () => {
    const [accessToken, setAccessToken] = useState(null);
    const [hasBeenLoaded, setHasBeenLoaded] = useState(false);

    const setAccessTokenExtended = (value: String|null, onlyInStorage = false): void => {
        //should be first, because the state call has some conditions bound
        if(value) {
            setAccessTokenToStorage(value);
        } else {
            revokeAccessToken();
        }

        if(!onlyInStorage) {
            setAccessToken(value);
        }
    };

    useEffect(() => {
        const value = getAccessTokenToStorage();

        if(value) {
            setAccessToken(value);
        }

        setHasBeenLoaded(true);
    }, []);

    return {
        accessToken,
        setAccessToken: setAccessTokenExtended,
        hasBeenLoaded,
    };
};