const MainContent = ({children}) => (
    <div className="main-content">
        <div className="page-content">
            <div className="container-fluid">
                {children}
            </div>
        </div>
    </div>
);

export default MainContent;