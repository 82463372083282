import Modal from "../../../../../../components/Modal/Modal";
import style from './style.module.css';
import Loading from "../../../../../../components/Loading/Loading";
import {useEffect, useState} from "react";
import ctx from 'classnames'
import TryAgainStatement from "../../../../../../components/TryAgainStatement/TryAgainStatement";
import {
    AJAX_LOADING_STATUS_ERROR,
    AJAX_LOADING_STATUS_IDLE,
    AJAX_LOADING_STATUS_LOADING, AJAX_LOADING_STATUS_SUCCESS
} from "../../../../../../library/http";
import {getHost} from "../../../../http/hosts";
import type {Host} from "../../../../types/hosts";
import {getTutorialVideoID} from "../../../../../../config";
import {showSuccessMessage} from "../../../../../../library/messages";

var timeout = null;

const YOUTUBE_TUTORIAL_VIDEO_ID = getTutorialVideoID();

const NewPlatformConnectionForHostModal = ({onClose, hostId, licenseCode}: {
    onClose: Function,
    hostId: String,
    licenseCode: String
}) => {
    const [loadingState, setLoadingState] = useState(AJAX_LOADING_STATUS_IDLE)
    const [host, setHost] = useState(null);

    const loadData = async () => {
        if(loadingState === AJAX_LOADING_STATUS_LOADING) {
            return;
        }

        setLoadingState(AJAX_LOADING_STATUS_LOADING);

        const response: ?Host = await getHost(hostId);

        if(response) {
            setHost(response);
            if(parseInt(response.StatusId) === 3) {
                setLoadingState(AJAX_LOADING_STATUS_SUCCESS);

                showSuccessMessage('The platform was successfully connected!');
            } else {
                timeout = setTimeout(() => {
                    loadData();
                }, 1000);
            }
        } else {
            setLoadingState(AJAX_LOADING_STATUS_ERROR);
            setHost(null)
        }
    };

    useEffect(() => {
        loadData();

        return () => {
            clearTimeout(timeout)
        };
    }, [])

    if(loadingState === AJAX_LOADING_STATUS_IDLE) {
        return null;
    }
    
    return (
        <Modal size={"large"} onClose={onClose} modalTitle={<ModalTitle />} canHideSubmitButton canHideFooter={loadingState !== AJAX_LOADING_STATUS_SUCCESS}>
            <div className={style.promo}>
                <img src={require("./img/connection.png")} />
            </div>
            {loadingState === AJAX_LOADING_STATUS_SUCCESS && (
                <div className={style.statusConnected}>
                    <span className={style.statusConnectedIcon}>
                        <i className="ri-check-line" />
                    </span>
                    <strong>Status:</strong> Connected
                </div>
            )}
            {loadingState === AJAX_LOADING_STATUS_LOADING && (
                <Loading noStyle labelStyle={style.loader} label={"Status: waiting for agent connection..."} />
            )}
            {loadingState === AJAX_LOADING_STATUS_ERROR && (
                <TryAgainStatement onTryAgainBtnClick={() => loadData()} />
            )}
            <div className={style.wizard}>
                {!!host && (
                    <div className={style.license}>
                        <strong>Name:</strong> {host.HostName} <br />
                        <strong>License:</strong> {licenseCode}
                    </div>
                )}
                <strong className={style.help}>
                    Activating Monitoring agent for the first time? Check this video tutorial:
                </strong>
                <div className={ctx("d-flex justify-content-center", style.tutorial)}>
                    <iframe
                        width="640"
                        height="360"
                        src={`https://www.youtube.com/embed/${YOUTUBE_TUTORIAL_VIDEO_ID}?autoplay=1`}
                        allow="autoplay, encrypted-media, fullscreen"
                        allowFullScreen="allowfullscreen"
                        frameBorder="0" />
                </div>
            </div>
        </Modal>
    );
};


export default NewPlatformConnectionForHostModal;


const ModalTitle = () => (
    <div className={style.title}>
        <div>New platform connection</div>
        <small className={"text-muted small"}>[Step 2 of 2]</small>
    </div>
);