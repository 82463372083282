import {useEffect, useState} from "react";
import {
    AJAX_LOADING_STATUS_ERROR,
    AJAX_LOADING_STATUS_IDLE,
    AJAX_LOADING_STATUS_LOADING,
    AJAX_LOADING_STATUS_SUCCESS
} from "../../../../../library/http";
import {getLicenses} from "../../../http/licenses";
import Loading from "../../../../../components/Loading/Loading";
import TryAgainStatement from "../../../../../components/TryAgainStatement/TryAgainStatement";
import {deleteHost, getHosts} from "../../../http/hosts";
import type {Host} from "../../../types/hosts";
import ctx from 'classnames'
import style from './Platforms.module.css'
import AddNewPlatformModal from "./Modals/AddNewPlatformModal";
import NewPlatformConnectionForHostModal from "./Modals/NewPlatformConnectionForHostModal";

import {showInfoMessage} from "../../../../../library/messages";

const TIMEOUT_SECONDS = 10

const Platforms = ({license, setLicense, hosts, setHosts, maxNumberOfHosts}: {
    license: Object|null,
    hosts: Array,
    setLicense: Function,
    setHosts: Function,
    maxNumberOfHosts: Number,
}) => {
    const [loadingState, setLoadingState] = useState(AJAX_LOADING_STATUS_IDLE);
    const [isAddNewPlatformModalShown, setIsAddNewPlatformModalShown] = useState(false);
    const [createdHostId, setCreatedHostId] = useState(null);

    let timeout;

    const loadData = async (isSilent = false) => {
        if(!isSilent) {
            setLoadingState(AJAX_LOADING_STATUS_LOADING);
        }

        const licensesResponse = await getLicenses();
        const hostsResponse = await getHosts();

        if(Array.isArray(licensesResponse) && licensesResponse.length && Array.isArray(hostsResponse)) {
            setHosts(hostsResponse);
            setLicense(licensesResponse[0]);

            setLoadingState(AJAX_LOADING_STATUS_SUCCESS);
        } else {
            setLoadingState(AJAX_LOADING_STATUS_ERROR);
            clearTimeout(timeout);
        }
    };

    const startTimeout = () => {
        clearTimeout(timeout);

        timeout = setTimeout(async () => {
            await loadData(true);

            startTimeout();
        }, TIMEOUT_SECONDS*1000);
    };

    useEffect(() => {
        loadData();

        startTimeout();

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    if(loadingState === AJAX_LOADING_STATUS_IDLE) {
        return null;
    }

    return (
        <>
            <div className={ctx("card", style.card)}>
                {maxNumberOfHosts > 0 && (
                    <button onClick={() => setIsAddNewPlatformModalShown(true)} type="button" className={ctx("btn btn-sm btn-outline-primary waves-effect waves-light", style.cardCta)}>
                        <i className="ri-add-line" />
                        Add platform
                    </button>
                )}
                <div className="card-body">
                    <h4 className="card-title mb-4">My platforms and VPS's</h4>
                    {loadingState === AJAX_LOADING_STATUS_LOADING && (
                        <Loading />
                    )}
                    {loadingState === AJAX_LOADING_STATUS_ERROR && (
                        <TryAgainStatement onTryAgainBtnClick={() => loadData()} />
                    )}
                    {loadingState === AJAX_LOADING_STATUS_SUCCESS && (
                        license ? (
                            <div className="table-responsive">
                                <table className="table">
                                    <thead className={"table-light"}>
                                    <tr>
                                        <th>#</th>
                                        <th>Type</th>
                                        <th>Name</th>
                                        <th className={"text-center"}>Status</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {hosts.length ? (
                                        hosts.map((host: Host, index: Number) => (
                                            <tr key={host.HostId}>
                                                <td>{index+1}</td>
                                                <td>{host.HostType}</td>
                                                <td>{host.HostName}</td>
                                                <td className={"text-center"}>
                                                    {parseInt(host.StatusId) === 1 && (
                                                        <div className="badge badge-soft-warning font-size-12">Waiting</div>
                                                    )}
                                                    {parseInt(host.StatusId) === 2 && (
                                                        <div className="badge badge-soft-info font-size-12">Inactive</div>
                                                    )}
                                                    {parseInt(host.StatusId) === 3 && (
                                                        <div className="badge badge-soft-success font-size-12">OK</div>
                                                    )}
                                                    {parseInt(host.StatusId) === 4 && (
                                                        <div className="badge badge-soft-danger font-size-12">Problem</div>
                                                    )}
                                                    {![1, 2, 3, 4].includes(parseInt(host.StatusId)) && (
                                                        <div className="badge badge-soft-info font-size-12">??unknown??</div>
                                                    )}
                                                </td>
                                                <td className={"cell-hoverable"}>
                                                    <button
                                                        onClick={async () => {
                                                            // eslint-disable-next-line no-restricted-globals
                                                            if(!confirm("Do you really want to remove the platform?"))  {
                                                                return;
                                                            }

                                                            await deleteHost(host.HostId)

                                                            loadData(true);

                                                            showInfoMessage("The platform was removed.");
                                                        }}
                                                        type="button" className="btn btn-sm btn-danger waves-effect waves-light">
                                                        Remove
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={4}>
                                                You have not added any platforms yet.
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div>No license information</div>
                        )
                    )}
                </div>
            </div>
            {isAddNewPlatformModalShown && (
                <AddNewPlatformModal
                    licenseCode={license.LicenseNumber}
                    onClose={() => setIsAddNewPlatformModalShown(false)}
                    onSuccess={hostId => {
                        setCreatedHostId(hostId)
                        loadData(true)
                    }}
                />
            )}
            {!!createdHostId && (
                <NewPlatformConnectionForHostModal
                    licenseCode={license.LicenseNumber}
                    hostId={createdHostId}
                    onClose={() => setCreatedHostId(null)}
                />
            )}
        </>
    );
};

export default Platforms;