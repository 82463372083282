import {useEffect, useState} from "react";
import {getDebugCredentials} from "../libs";
import {setPageTitle} from "../../../library/title";
import {login} from "../http";
import style from "./Login.module.css";
import {POSITION_BOTTOM_LEFT, showErrorMessage} from "../../../library/messages";

const Login = ({onLogin}) => {
    const [email, setEmail] = useState(getDebugCredentials()["email"])
    const [password, setPassword] = useState(getDebugCredentials()["pass"])
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        setPageTitle("Log in");
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsSubmitting(true);
        const accessToken = await login(email, password);
        setIsSubmitting(false);

        if(accessToken) {
            onLogin(accessToken);
        } else {
            showErrorMessage("Unknown credentials provided!", POSITION_BOTTOM_LEFT);
        }
    };

    return (
        <div>
            <div>
                <div className="container-fluid p-0">
                    <div className="row g-0">
                        <div className="col-lg-4">
                            <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                <div className="w-100">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-9">
                                            <div>
                                                <div className="text-center">
                                                    <div>
                                                        <a href="/" className="">
                                                            <img src={require("../../../assets/img/logo.png")} />
                                                            <span className={style.logoText}>QuantMonitor</span>
                                                        </a>
                                                    </div>

                                                    <h4 className="font-size-18 mt-4">Welcome Back !</h4>
                                                    <p className="text-muted">Sign in to continue.</p>
                                                </div>

                                                <div className="p-2 mt-5">
                                                    <form className="" action="index.html" method={"post"} onSubmit={handleSubmit}>

                                                        <div className="mb-3 auth-form-group-custom mb-4">
                                                            <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                            <label htmlFor="username">Username</label>
                                                            <input disabled={isSubmitting} required value={email} onChange={e => setEmail(e.target.value)} type="email" className="form-control" id="username"
                                                                   placeholder="Enter username" />
                                                        </div>

                                                        <div className="mb-3 auth-form-group-custom mb-4">
                                                            <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                            <label htmlFor="userpassword">Password</label>
                                                            <input disabled={isSubmitting} value={password} onChange={e => setPassword(e.target.value)} required type="password" className="form-control"
                                                                   id="userpassword" placeholder="Enter password" />
                                                        </div>

                                                        <div className="form-check" hidden>
                                                            <input type="checkbox" className="form-check-input"
                                                                   id="customControlInline" />
                                                            <label className="form-check-label"
                                                                   htmlFor="customControlInline">Remember me</label>
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <button
                                                                disabled={isSubmitting}
                                                                className="btn btn-primary w-md waves-effect waves-light"
                                                                type="submit">Log In
                                                            </button>
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <a href="/lost-password" className="text-muted"><i
                                                                className="mdi mdi-lock me-1"></i> Forgot your password?</a>
                                                        </div>
                                                    </form>
                                                </div>

                                                <div className="mt-5 text-center">
                                                    <p>Don't have an account ? <a href="https://www.quantmonitor.net/sign-in/"
                                                                                  className="fw-medium text-primary"> Register </a>
                                                    </p>
                                                    <p>
                                                        <small>&copy; QuantMonitor.net</small>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="authentication-bg">
                                <div className="bg-overlay"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Login;