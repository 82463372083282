import type {Media} from "../types/media";
import {httpGet, httpPost} from "../../../library/http";

export const getNotificationsEmails = async (): Promise<Array<Media>|null> => {
    const response = await httpGet("media/get");

    return Array.isArray(response) ? response : null;
};

export const addNewNotificationEmail = async (email: String): Promise<Boolean> => {
    const response = await httpPost("media/create", {email});

    return response?.Status === "Success";
};

export const deleteNotificationEmail = async(profile: Media): Promise<Boolean> => {
    const response = await httpPost("media/delete", {MediaId: profile.MediaId, Value: profile.Value});

    return response?.Status === "Success";
};