import {httpPost} from "../../library/http";

export const login = async (email, password): Promise<String|null> => {
    const response = await httpPost("login", {email, password}, false);

    return response ? response : null;
};

export const requestLostPassword = async (email: String): Promise<Boolean> => {
    const response = await httpPost("password/request", {email}, false);

    return response?.Status === "Success";
};

export const updatePassword = async (token: String, Password: String): Promise<Boolean> => {
    const response = await httpPost(`password/${token}`, {Password}, false);

    return response?.Status === "Success";
};