import {useState, useRef} from 'react'
import Modal from "../../../../components/Modal/Modal";
import {addNewNotificationEmail} from "../../http/media";
import {validateEmail} from "../../../../library/email";
import {showErrorMessage, showSuccessMessage, showWarningMessage} from "../../../../library/messages";

const AddNewNotificationEmailModal = ({onClose, onSuccess}: {
    onClose: Function,
    onSuccess: Function,
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [email, setEmail] = useState("")

    const modalEl = useRef(null);

    const handleSubmit = async e => {
        e.preventDefault();

        if(isSubmitting) {
            return;
        }

        if(!validateEmail(email)) {
            showWarningMessage("Please, enter a valid email!");
            return;
        }

        setIsSubmitting(true);
        const response = await addNewNotificationEmail(email);
        setIsSubmitting(false);

        if(response) {
            modalEl.current.close();

            onSuccess();

            showSuccessMessage(`The email ${email} was added!`);
        } else {
            showErrorMessage("Error occurred, please, try again.");
        }
    };

    return (
        <Modal
            ref={modalEl}
            modalTitle={"Add new notification email"}
            onClose={onClose}
            onSubmitBtnClick={handleSubmit}
            isSubmitBtnDisabled={isSubmitting}
        >
            <form method={"post"} onSubmit={handleSubmit}>
                <fieldset disabled={isSubmitting}>
                    <div>
                        <label>E-mail</label>
                        <input autoFocus name={"email"} placeholder={"Enter your email..."} required type={"email"} value={email} onChange={e => setEmail(e.target.value.toLowerCase().trim())} className={"form-control"}/>
                    </div>
                </fieldset>
            </form>
        </Modal>
    );
};

export default AddNewNotificationEmailModal;