import Modal from "../../../../../components/Modal/Modal";
import {useRef, useState} from "react";
import {showErrorMessage, showSuccessMessage, showWarningMessage} from "../../../../../library/messages";
import {createWidget} from "../../../http/widgets";
import type {Widget} from "../../../types/widgets";
import {getIframeSrcFromHTMLCode} from "../../../../../library/iframe";

export const AddNewWidgetModal = ({onClose, onSuccess, lastWidget = null}: {
    onClose: Function,
    onSuccess: Function,
    lastWidget?: Widget,
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false)
    const[name, setName] = useState("")
    const[iframeCode, setIframeCode] = useState("")

    const modalEl = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!name.length) {
            showWarningMessage('Enter the widget\'s name.')
            return
        } else if (!iframeCode.length) {
            showWarningMessage('Enter the widget\'s iframe code.')
            return
        }

        let iframeCodeValue
        try {
            iframeCodeValue = getIframeSrcFromHTMLCode(iframeCode)
        } catch(err) {
            showWarningMessage('The iframe code seems corrupted. Please verify and try again.')
            return
        }

        setIsSubmitting(true)
        const response = await createWidget(name, iframeCodeValue, (lastWidget?.Order??0) + 1)
        setIsSubmitting(false)

        if(response) {
            onSuccess()

            modalEl.current.close();

            //this is buggy
            //onClose()

            showSuccessMessage('The widget was successfully created.')
        } else {
            showErrorMessage('Error creating your widget. Please, try again.');
        }
    }

    return (
        <Modal ref={modalEl} submitBtnLabel={"Submit"} onClose={onClose} modalTitle={"Add new widget"} onSubmitBtnClick={handleSubmit} isSubmitBtnDisabled={isSubmitting}>
            <form method={"POST"} action={"/"} onSubmit={e => e.preventDefault()}>
                <fieldset disabled={isSubmitting}>
                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label required">Name</label>
                        <div className="col-sm-9">
                            <input value={name} onChange={e => setName(e.target.value)} required minLength={5} maxLength={100} className="form-control" type="text" placeholder="Widget name..." />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="col-sm-3 col-form-label required">Iframe code</label>
                        <div className="col-sm-9">
                            <textarea value={iframeCode} rows={4} onChange={e => setIframeCode(e.target.value)}  maxLength={1000} required className="form-control" placeholder="HTML code for iframe..." />
                        </div>
                    </div>
                </fieldset>
            </form>
        </Modal>
    )
};