import axios from "axios";
import {getApiUrl} from "../config";
import {getAccessToken, revokeAccessToken} from "../auth/token";

export const httpGet = async (endpoint: string, params = {}, isAuthorized = true) => {
    try {
        const headers = {};

        if(isAuthorized) {
            const token = getAccessToken();

            if(token) {
                headers["x-auth-token"] = token;
            } else {
                throw new Error("Could not retrieve the app access token!");
            }
        }

        const response = await axios.get(getApiUrl()+endpoint, {
            withCredentials: true,
            params,
            headers,
        });

        const responseHttpCode = parseInt(response.status);
        console.log("Server GET response: %d, %o", responseHttpCode, response);

        if([200, 201].includes(responseHttpCode)) {
            return response.data;
        }

        throw new Error("Error http code returned");
    } catch (error) {
        console.error(`Ajax call "${endpoint}" failed: %o`, error);

        if(error?.response?.status === 403) {
            console.warn("The token expired. Redirecting back to login page.")
            revokeAccessToken();

            document.location.href = "/?error="+encodeURIComponent("Your authorization expired. Please, log in.");
        }
    }

    return null;
};

export const httpPost = async (endpoint, params = {}, isAuthorized = true) => {
    try {
        const headers = {};

        if(isAuthorized) {
            const token = getAccessToken();

            if(token) {
                headers["x-auth-token"] = token;
            } else {
                throw new Error("Could not retrieve the app access token!");
            }
        }

        const response = await axios.post(getApiUrl()+endpoint, params, {
            headers,
            withCredentials: true,
        });

        const responseHttpCode = parseInt(response.status);
        console.log("Server POST response: %d %o", responseHttpCode, response);

        if([200, 201].includes(responseHttpCode)) {
            return response.data;
        }

        throw new Error("Error http code returned")
    } catch (error) {
        console.error("Ajax call failed: %o", error);

        if(error?.response?.status === 403) {
            console.warn("The token expired. Redirecting back to login page.")
            revokeAccessToken();

            document.location.href = "/?error="+encodeURIComponent("Your authorization expired. Please, log in.");
        }
    }

    return null;
};

const AJAX_LOADING_STATUS_IDLE = 1;
const AJAX_LOADING_STATUS_LOADING = 2;
const AJAX_LOADING_STATUS_SUCCESS = 3;
const AJAX_LOADING_STATUS_ERROR = 4;

export {
    AJAX_LOADING_STATUS_IDLE,
    AJAX_LOADING_STATUS_LOADING,
    AJAX_LOADING_STATUS_SUCCESS,
    AJAX_LOADING_STATUS_ERROR,
};