import React, {useState, useEffect} from "react";

import ErrorBoundary from "./components/ErrorBoundary";
import {BrowserRouter} from 'react-router-dom';
import style from './App.module.css';
import {AppContext} from "./context";
import type {AppContextInterface} from "./context";
import LoggedIn from "./modules/LoggedIn/LoggedIn";
import NotLoggedIn from "./modules/NotLoggedIn/NotLoggedIn";
import {useGetAccessToken} from "./auth/hooks";
import {BackgroundRefresh} from "./library/backgroundRefresh";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";

function App() {
    const {accessToken, setAccessToken, hasBeenLoaded} = useGetAccessToken();

    const isLoggedIn = accessToken !== null;

    const handleLogin = async (accessToken: String): void => {
        setAccessToken(accessToken);

        document.location.href = "/";
    };

    const handleLogout = () => {
        setAccessToken(null, true);

        document.location.href = "/";
    };

    const getGlobalState = (): AppContextInterface => ({
        handleLogout,
    });

    if(!hasBeenLoaded) {
        return null;
    }

    return (
        <BrowserRouter>
            <ErrorBoundary>
                <div className={style.app}>
                    <AppContext.Provider value={getGlobalState()}>
                        {isLoggedIn ? (
                            <>
                                <LoggedIn />
                                <BackgroundRefresh />
                            </>
                        ) : (
                            <NotLoggedIn onLogin={handleLogin} />
                        )}
                    </AppContext.Provider>
                    <ToastContainer />
                </div>
            </ErrorBoundary>
        </BrowserRouter>
    );
}

export default App;
