export const getIframeSrcFromHTMLCode = (htmlCode): string => {

    try {
        //@author https://stackoverflow.com/a/21870431/2880184 domparsing
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(htmlCode, 'text/html');

        const iframe = htmlDoc.querySelector('iframe')
        const src = iframe?.getAttribute('src')
        const height = iframe?.getAttribute('height')

        let attrs = ''

        if(height){
            attrs += ` height=${height} `
        }

        if(src) {
            return `<iframe ${attrs} src="${src}"></iframe>`;
        }

        throw new Error("Could not find SRC attribute.")
    } catch(err) {
        console.error("error parsing iframe code", err)
    }

    throw new Error('Error parsing iframe code');
};