import type {Widget} from "../types/widgets";
import {httpGet, httpPost} from "../../../library/http";

export const getWidgets = async (): Promise<Array<Widget>|null> => {
    const response = await httpGet("widgets/get");

    if(Array.isArray(response)) {
        return response.map((w: Widget) => {
            w.Order = parseInt(w.Order)

            return w
        })
    }

    return null;
};

export const createWidget = async (name: String, iframeCode: string, order: Number): Promise<Boolean> => {
    const response = await httpPost("widgets/create", {
        Name: name,
        Data: iframeCode,
        Order: order,
    });

    return response?.Status === "Success";
};

export const editWidget = async (id, name: String, iframeCode: string, order: Number): Promise<Boolean> => {
    const response = await httpPost(`widgets/edit`, {
        WidgetId: id,
        Name: name,
        Data: iframeCode,
        Order: order,
    });

    return response?.Status === "Success";
};

export const deleteWidget = async (id): Promise<Boolean> => {
    const response = await httpPost(`widgets/delete`, {
        WidgetId: id,
     });

    return response?.Status === "Success";
};