import React, {PureComponent} from 'react';
import {ErrorInfo} from "react";
import {isProductionMode} from "../config";

export default class ErrorBoundary extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            errorMessage: null,
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("App error: %o", error)
        console.error("Error info: %o", errorInfo)

        this.setState({errorMessage: error.message})
    }

    render() {
        if (this.state.hasError) {
            return (
                <div>
                    <h1 style={{marginBottom: 20}}>Something went wrong.</h1>
                    <p>Application error occurred</p>
                    <div>
                        {isProductionMode() ? (
                            "Please, contact the administrator for more information."
                        ) : (
                            this.state.errorMessage
                        )}
                    </div>
                </div>
            )
        }

        return this.props.children;
    }
}