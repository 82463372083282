import Problems from "./components/Problems";
import Platforms from "./components/Platforms/Platforms";
import {useEffect, useState} from "react";
import {setPageTitle} from "../../../library/title";
import type {License} from "../types/licenses";
import type {Host} from "../types/hosts";
import Widgets from "./components/Widgets/Widgets";
import ctx from "classnames";
import style from "./components/Platforms/Platforms.module.css";

const Dashboard = ({license, setLicense, hosts, setHosts}: {
    license: License,
    setLicense: Function,
    hosts: Array<Host>,
    setHosts: Function,
}) => {

    useEffect(() => {
        setPageTitle("Dashboard");
    }, []);

    const maxNumberOfHosts = license?.Param?.MaxNumberOfHosts ? parseInt(license.Param.MaxNumberOfHosts) : 0;
    const availablePlatforms = maxNumberOfHosts - hosts.length;

    const isMaxNumberOfHostsUnlimited = maxNumberOfHosts === 0;

    const licenseIsActive = parseInt(license?.Status) === 1;

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Dashboard</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href="/">QuantMonitor</a></li>
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2">Your License Code</p>
                                    <h4 className="mb-0">{license?.LicenseNumber ?? "-"}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2">Available Platforms</p>
                                    <h4 className="mb-0">
                                        {isMaxNumberOfHostsUnlimited ? (
                                            <span className={"badge bg-secondary"}>Unlimited</span>
                                        ) : (
                                            `${availablePlatforms}/${maxNumberOfHosts}`
                                        )}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {licenseIsActive ? (
                    <div className="col-md-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex">
                                    <div className="flex-1 overflow-hidden">
                                        <p className="text-truncate text-success font-size-14 mb-2">Monitoring subscription</p>
                                        <h4 className="mb-0">
                                            <span className={"badge bg-success"}>ACTIVE</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="col-md-3">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex">
                                    <div className="flex-1 overflow-hidden">
                                        <p className="text-truncate text-danger font-size-14 mb-2">Monitoring subscription</p>
                                        <h4 className="mb-0">
                                            <span className={"badge bg-danger"}>EXPIRED</span>
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border-top py-3">
                                <div className="text-truncate">
                                <span className="text-muted ms-2">
                                    <a target={"_blank"} className={"text-muted"} href={"http://quantmonitor.net/pricing"}>Extend subscription &raquo;</a>
                                </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className="col-md-3">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="flex-1 overflow-hidden">
                                    <p className="text-truncate font-size-14 mb-2">Custom widget</p>
                                    <button onClick={() => {
                                        window.jQuery("body").trigger("qm:custom:newWidget")
                                    }} type="button" className={ctx("btn btn-sm btn-outline-primary waves-effect waves-light")}>
                                        <i className="ri-add-line" />
                                        Add custom widget
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6">
                    <Platforms
                        license={license}
                        setLicense={setLicense}
                        hosts={hosts}
                        setHosts={setHosts}
                        maxNumberOfHosts={maxNumberOfHosts}
                    />
                </div>

                <div className="col-lg-6">
                    <Problems />
                </div>
            </div>

            <Widgets />
        </>
    );
};

export default Dashboard;