import {Route, Routes} from "react-router-dom";
import LostPassword from "./LostPassword/LostPassword";
import Login from "./Login/Login";
import SetNewPassword from "./SetNewPassword/SetNewPassword";

const NotLoggedIn = ({onLogin}) => {

    return (
        <Routes>
            <Route path="/lost-password" element={<LostPassword />} />
            <Route path="/password/:token" element={<SetNewPassword />} />

            <Route
                path="*"
                element={<Login onLogin={onLogin} />}
            />
        </Routes>
    )
};

export default NotLoggedIn;