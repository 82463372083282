const TOKEN_NAME = "xAppAccessToken";

export const getAccessToken = (): String|null => {
    try{
        const value = localStorage.getItem(TOKEN_NAME);

        return value ? value : null;
    } catch(err) {
        console.error("Errog getting access token: %o", err)
    }

    return null;
};

export const updateAccessToken = (value: String): void => {
    try{
        localStorage.setItem(TOKEN_NAME, value);
    } catch(err) {
        console.error("error updating access token: %o", err)
    }
};

export const revokeAccessToken = (): void => {
    try{
        localStorage.removeItem(TOKEN_NAME);
    } catch(err) {
        console.error("Error revoking access token: %o", err)
    }
};