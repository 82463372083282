import {useState, useEffect} from 'react'

export const useGetDarkMode = () => {
    const [isDarkMode, setIsDarkMode] = useState(null);

    useEffect(() => {
        try {
            const hasDarkModeBeenSet = parseInt(localStorage.getItem("isDarkMode"));

            setIsDarkModeInternal(hasDarkModeBeenSet === 1);
        } catch(err) {

        }
    }, []);

    const setIsDarkModeInternal = (doTurnOn: Boolean) => {
        setIsDarkMode(doTurnOn);

        try {
            localStorage.setItem("isDarkMode", doTurnOn ? 1 : 0);
        } catch(err) {

        }

        document.body.setAttribute("data-sidebar", doTurnOn ? "dark" : "light")
    } ;

    return [isDarkMode, setIsDarkModeInternal];
};

export const useGetSidebarCollapsed = () => {
    const [isCollapsed, setIsCollapsed] = useState(null);

    useEffect(() => {
        try {
            const isSidebarCollapsed = parseInt(localStorage.getItem("isSidebarCollapsed"));

            setIsCollapsedInternal(isSidebarCollapsed === 1);
        } catch(err) {

        }
    }, []);

    const setIsCollapsedInternal = (doCollapse: Boolean) => {
        setIsCollapsed(doCollapse);

        try {
            localStorage.setItem("isSidebarCollapsed", doCollapse ? 1 : 0);
        } catch(err) {

        }

        if(doCollapse) {
            document.body.classList.add("sidebar-enable");

            if(window.innerWidth > 992) {
                document.body.classList.add("vertical-collpsed");
            }
        } else {
            document.body.classList.remove("sidebar-enable");

            if(window.innerWidth > 992) {
                document.body.classList.remove("vertical-collpsed");
            }
        }
    } ;

    return [isCollapsed, setIsCollapsedInternal];
};