import {useEffect, useRef, useState} from 'react';
import Modal from "../../../../../../components/Modal/Modal";
import {
    MIN_LENGTH_HOST_NAME,
    validateAlphaNumericalCharacters,
    validateHostName, validateMinLength,
    validateStartsEndsWithNumber
} from "../../../../../../library/host";
import {createHost} from "../../../../http/hosts";
import style from './style.module.css'
import {showErrorMessage, showWarningMessage} from "../../../../../../library/messages";
import ctx from "classnames";

const TYPES = [
    {
        id: 1,
        name: "Metatrader 4",
    },
    {
        id: 2,
        name: "Metatrader 5",
    },
    {
        id: 3,
        name: "TradeStation",
    },
    {
        id: 7,
        name: "MultiCharts",
    },
];

const AddNewPlatformModal = ({licenseCode, onClose, onSuccess}: {
    onClose: Function,
    onSuccess: Function,
    licenseCode: String,
}) => {
    const [type, setType] = useState("");
    const [name, setName] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [description, setDescription] = useState("");
    const [license, setLicense] = useState(licenseCode);

    const [isBreakingRuleMinLength, setIsBreakingRuleMinLength] = useState(null)
    const [isBreakingRuleOnlyAlphaNumerical, setIsBreakingRuleOnlyAlphaNumerical] = useState(null)
    const [isBreakingRuleNotToStartEndWithNumber, setIsBreakingRuleNotToStartEndWithNumber] = useState(null)

    const modalEl = useRef(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const nameValue = name.trim()

        if(isSubmitting) {
            return;
        }

        if(!type) {
            showWarningMessage("Please, select a type!");
            return
        }

        if(nameValue.length < 1) {
            showWarningMessage("Please, choose a host name!")
            return;
        } else if (nameValue.length < 4) {
            showWarningMessage("The host name must be at least 4 characters.");
            return;
        }

        if(!validateHostName(nameValue)) {
            showWarningMessage("The host name can contains only letters, number or a hyphen!");
            return
        }

        if(!license) {
            showWarningMessage("Please, enter the license code!")
            return;
        }

        setIsSubmitting(true);
        const hostId = await createHost(nameValue, description, type, license);
        setIsSubmitting(false);

        if(hostId) {
            modalEl.current.close();

            onSuccess(hostId);
        } else {
            showErrorMessage("Error occurred, please, try again.");
        }
    };

    const ModalTitle = () => (
        <div className={style.title}>
            <div>New Platform/VPS setting</div>
            <small className={"text-muted small"}>[Step 1 of 2]</small>
        </div>
    );

    return (
        <Modal ref={modalEl} onClose={onClose} modalTitle={<ModalTitle />} submitBtnLabel={"Save and Connect!"} onSubmitBtnClick={handleSubmit}>
            <form method={"POST"} action={"/"}>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label required">Type</label>
                    <div className="col-sm-9">
                        <select className={"form-select"} value={type} required onChange={e => setType(e.target.value)}>
                            <option value="" disabled>Select...</option>
                            {TYPES.map(type => (
                                <option key={type.id} value={type.id}>{type.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label required">Name</label>
                    <div className="col-sm-9">
                        <input value={name} onChange={e => {
                            setName(e.target.value)

                            setIsBreakingRuleOnlyAlphaNumerical(validateAlphaNumericalCharacters(e.target.value))
                            setIsBreakingRuleNotToStartEndWithNumber(validateStartsEndsWithNumber(e.target.value))
                            setIsBreakingRuleMinLength(validateMinLength(e.target.value))
                        }} required minLength={5} maxLength={100} className="form-control" type="text" placeholder="The host name..." />
                    </div>
                    <div className="col-sm-3" />
                    <div className={"col-sm-9 mt-1"}>
                        <small data-valid={isBreakingRuleOnlyAlphaNumerical} className={ctx(isBreakingRuleOnlyAlphaNumerical === true && 'color-green', isBreakingRuleOnlyAlphaNumerical === false && 'color-red')}>
                            {isBreakingRuleOnlyAlphaNumerical === true && (<i className="ri-check-line" />)}
                            {isBreakingRuleOnlyAlphaNumerical === false && (<i className="ri-close-line" />)}
                            {isBreakingRuleOnlyAlphaNumerical === null && "- "}
                            Only alphanumerical characters or a hyphen
                        </small>
                        <br />
                        <small data-valid={isBreakingRuleNotToStartEndWithNumber} className={ctx(isBreakingRuleNotToStartEndWithNumber === true && 'color-green', isBreakingRuleNotToStartEndWithNumber === false && 'color-red')}>
                            {isBreakingRuleNotToStartEndWithNumber === true && (<i className="ri-check-line" />)}
                            {isBreakingRuleNotToStartEndWithNumber === false && (<i className="ri-close-line" />)}
                            {isBreakingRuleNotToStartEndWithNumber === null && "- "}
                            Can not start with a number or a hyphen
                        </small>
                        <br />
                        <small data-valid={isBreakingRuleMinLength} className={ctx(isBreakingRuleMinLength === true && 'color-green', isBreakingRuleMinLength === false && 'color-red')}>
                            {isBreakingRuleNotToStartEndWithNumber === true && (<i className="ri-check-line" />)}
                            {isBreakingRuleNotToStartEndWithNumber === false && (<i className="ri-close-line" />)}
                            {isBreakingRuleNotToStartEndWithNumber === null && "- "}
                            At least {MIN_LENGTH_HOST_NAME} characters
                        </small>
                    </div>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label">Description</label>
                    <div className="col-sm-9">
                        <input value={description} onChange={e => setDescription(e.target.value)}  maxLength={100} className="form-control" type="text" placeholder="Describe the host..." />
                    </div>
                </div>
                <div className="row mb-3">
                    <label className="col-sm-3 col-form-label required">License</label>
                    <div className="col-sm-9">
                        <input disabled value={license} onChange={e => setLicense(e.target.value)} required minLength={1} maxLength={100} className="form-control" type="text" placeholder="Enter the license code..." />
                    </div>
                </div>
            </form>
        </Modal>
    )
};

export default AddNewPlatformModal;