import {isProductionMode} from "../../config";

export const getDebugCredentials = () => {
    if(!isProductionMode()) {
        return {
            email: process.env?.REACT_APP_DEBUG_EMAIL ?? "",
            pass: process.env?.REACT_APP_DEBUG_PASS ?? "",
        };
    }

    return {
        email: "",
        pass: "",
    };
};