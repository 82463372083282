import {useEffect, useState} from 'react';

import {
    AJAX_LOADING_STATUS_ERROR,
    AJAX_LOADING_STATUS_IDLE,
    AJAX_LOADING_STATUS_LOADING,
    AJAX_LOADING_STATUS_SUCCESS
} from "../../../library/http";
import {setPageTitle} from "../../../library/title";
import {deleteNotificationEmail, getNotificationsEmails} from "../http/media";
import type {Media} from "../types/media";
import AddNewNotificationEmailModal from "./modals/AddNewNotificationEmailModal";
import {showSuccessMessage} from "../../../library/messages";

const Notifications = () => {
    const [loadingState, setLoadingState] = useState(AJAX_LOADING_STATUS_IDLE);
    const [data, setData] = useState([]);
    const [isAddNewEmailModalShown, setIsAddNewEmailModalShown] = useState(false)

    const loadData = async () => {
        setLoadingState(AJAX_LOADING_STATUS_LOADING);

        const data = await  getNotificationsEmails();

        if(Array.isArray(data)) {
            setData(data);
            setLoadingState(AJAX_LOADING_STATUS_SUCCESS, );
        } else {
            setData([]);
            setLoadingState(AJAX_LOADING_STATUS_ERROR);
        }
    };

    useEffect(() => {
        setPageTitle("Notifications");
    }, []);

    useEffect(() => {
        loadData();
    }, []);

    if(loadingState === AJAX_LOADING_STATUS_IDLE) {
        return null;
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Notifications</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href="/">QuantMonitor</a></li>
                                <li className="breadcrumb-item active">Notifications</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">

                            <h4 className="card-title">My notification emails</h4>
                            <p className="card-title-desc">
                                Set up the emails to notify you once something goes wrong.
                            </p>
                            <div className={"card-subheader"}>
                                <button className={"btn btn-primary waves-effect waves-light"} type={"button"}
                                        onClick={() => {
                                            setIsAddNewEmailModalShown(true);
                                        }}
                                >
                                    <i className="ri-add-line" />
                                    New email
                                </button>
                            </div>

                            <table className="table mb-0  table-hover">
                                <thead className="table-light">
                                <tr>
                                    <th width={250}>E-mail</th>
                                    <th className={"text-end"}>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {data.map((item: Media) => (
                                        <tr key={item.MediaId}>
                                            <td>{item.Value}</td>
                                            <td className={"text-end cell-hoverable"}>
                                                <button
                                                    onClick={async () => {
                                                        // eslint-disable-next-line no-restricted-globals
                                                        if(!confirm("Do you really want to remove the email address?"))  {
                                                            return;
                                                        }

                                                        await deleteNotificationEmail(item)
                                                        loadData();

                                                        showSuccessMessage("The email was removed!");
                                                    }}
                                                    type="button" className="btn btn-sm btn-danger waves-effect waves-light">
                                                    Remove
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                {data.length === 0 && (
                                    <tfoot>
                                        <tr>
                                            <td colSpan={2}>
                                                You have not added any notification emails yet.
                                            </td>
                                        </tr>
                                    </tfoot>
                                )}
                            </table>

                        </div>
                    </div>
                </div>
            </div>

            {isAddNewEmailModalShown && (
                <AddNewNotificationEmailModal onSuccess={() => loadData()} onClose={() => setIsAddNewEmailModalShown(false)}
                />
            )}
        </>
    );
};

export default Notifications;