import React, {useEffect} from 'react'
import {getHosts} from "../modules/LoggedIn/http/hosts";

export const doRefresh = () => {
    console.log("refreshing")
    //basically does not matter, any request is possible
    getHosts();
};

let timeout = null;

const startRefreshing = () => {
    clearTimeout(timeout);

    timeout = setTimeout(() => {
        doRefresh();

        startRefreshing();
    }, 30*1000);
}


export const BackgroundRefresh = () => {
    useEffect(() => {
        startRefreshing();
    }, []);

    return null;
};