import {toast} from 'react-toastify'


export const showInfoMessage = (text: String): void =>{
    toast.info(text);
}

export const showSuccessMessage = (text: String): void =>{
    toast.success(text);
}

export const showWarningMessage = (text: String): void =>{
    toast.warn(text);
}

export const showErrorMessage = (text: String, position = null): void =>{
    const options = {};

    if(position) {
        options.position = position;
    }

    toast.error(text, options);
}

export const POSITION_BOTTOM_LEFT = "bottom-left";