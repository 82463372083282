import {useState, useEffect} from "react";
import {
    AJAX_LOADING_STATUS_ERROR,
    AJAX_LOADING_STATUS_IDLE,
    AJAX_LOADING_STATUS_LOADING,
    AJAX_LOADING_STATUS_SUCCESS
} from "../../../../library/http";
import {getProblems} from "../../http/events";
import type {Problem} from "../../types/events";
import Loading from "../../../../components/Loading/Loading";
import TryAgainStatement from "../../../../components/TryAgainStatement/TryAgainStatement";
import {formatTimestampWithTZ} from "../../../../library/date";

const TIMEOUT_SECONDS = 10

const Problems = () => {
    const [data, setData] = useState([]);
    const [loadingState, setLoadingState] = useState(AJAX_LOADING_STATUS_IDLE);

    let timeout;

    const loadData = async (isSilent = false) => {
        if(!isSilent) {
            setLoadingState(AJAX_LOADING_STATUS_LOADING);
        }

        const problems = await getProblems();

        if(Array.isArray(problems)) {
            setLoadingState(AJAX_LOADING_STATUS_SUCCESS);
            setData(problems);
        } else {
            setLoadingState(AJAX_LOADING_STATUS_ERROR);
            setData([]);
            clearTimeout(timeout);
        }
    };

    const startTimeout = () => {
        clearTimeout(timeout);

        timeout = setTimeout(async () => {
            await loadData(true);

            startTimeout();
        }, TIMEOUT_SECONDS*1000);
    };

    useEffect(() => {
        loadData();

        startTimeout();

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    if(loadingState === AJAX_LOADING_STATUS_IDLE) {
        return null;
    }

    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title d-flex justify-content-between align-items-center mb-4">
                    Active issues
                    {data.length > 0 && (
                        <span className="badge rounded-pill bg-danger">{data.length} active issues!</span>
                    )}
                </h4>
                <>
                    {loadingState === AJAX_LOADING_STATUS_LOADING && (
                        <Loading label={"Loading..."} />
                    )}
                    {loadingState === AJAX_LOADING_STATUS_ERROR && (
                        <TryAgainStatement onTryAgainBtnClick={() => loadData()} />
                    )}
                    {loadingState === AJAX_LOADING_STATUS_SUCCESS && (
                        data.length ? (
                            <div className="table-responsive">
                                <table className="table table-light mt-3">
                                    <thead>
                                    <tr>
                                        <th width={20}>#</th>
                                        <th>Problem</th>
                                        <th width={150}>Last change</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data.map((problem: Problem, index) => (
                                        <tr key={problem.EventID} className={"table-danger"}>
                                            <td>{index+1}</td>
                                            <td>{problem.Name}</td>
                                            <td>{formatTimestampWithTZ(problem.Timestamp)}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p className="card-title-desc">
                                There are no problems currently.
                            </p>
                        )
                    )}
                </>
            </div>
        </div>
    );
};

export default Problems;