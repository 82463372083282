import style from './Widgets.module.css'
import ctx from "classnames";
import {useEffect, useState} from "react";
import {
    AJAX_LOADING_STATUS_ERROR,
    AJAX_LOADING_STATUS_IDLE,
    AJAX_LOADING_STATUS_LOADING, AJAX_LOADING_STATUS_SUCCESS
} from "../../../../../library/http";
import type {Widget} from "../../../types/widgets";
import {deleteWidget, editWidget, getWidgets} from "../../../http/widgets";
import {sortAlphabetically} from "../../../../../library/sort";
import TryAgainStatement from "../../../../../components/TryAgainStatement/TryAgainStatement";
import {AddNewWidgetModal} from "./AddNewWidgetModal";
import {showSuccessMessage} from "../../../../../library/messages";
import {EditWidgetModal} from "./EditWidgetModal";


const Widgets = () => {
    const [widgets, setWidgets] = useState([])
    const [ajaxState, setAjaxState] = useState(AJAX_LOADING_STATUS_IDLE)

    const [editedWidget, setEditedWidget] = useState(null)
    const [isAddNewWidgetModalShown, setIsAddNewWidgetModalShown] = useState(false)

    const handleOpenAddNewWidgetModal = () => {
        setIsAddNewWidgetModalShown(true)
    }

    const loadData = async () => {
        setAjaxState(AJAX_LOADING_STATUS_LOADING)

        const data = await getWidgets();

        if(Array.isArray(data)) {
            data.sort((a: Widget, b: Widget) => sortAlphabetically(a, b, 'Order'))

            setWidgets(data)
            setAjaxState(AJAX_LOADING_STATUS_SUCCESS)
        } else {
            setAjaxState(AJAX_LOADING_STATUS_ERROR)
        }
    };

    const handleDeleteWidget = (widget: Widget) => {
        if(!window.confirm('Do you really want to delete the widget from your dashboard?')) {
            return;
        }

        deleteWidget(widget.WidgetId);

        const widgetsCopy = [...widgets]
        const targetWidgetIndex = widgetsCopy.findIndex(w => w.WidgetId === widget.WidgetId)

        if(targetWidgetIndex !== -1) {
            widgetsCopy.splice(targetWidgetIndex, 1)
        }

        setWidgets(widgetsCopy)

        showSuccessMessage('The widget was successfully removed.')
    }

    const handleMoveWidgetToPreviousPosition = async  (widget: Widget) => {
        const widgetArrIndex = widgets.findIndex((w: Widget) => w.WidgetId === widget.WidgetId)

        if(widgetArrIndex !== -1) {
            const prevWidget: Widget = widgets[widgetArrIndex-1]

            if(prevWidget) {
                const oldOrder = parseInt(widget.Order);
                const prevOrder = parseInt(prevWidget.Order)

                //move target widget to desired location
                await editWidget(widget.WidgetId, widget.Name, widget.Data, prevOrder)

                //move the previoius widget to target's widget location
                await editWidget(prevWidget.WidgetId, prevWidget.Name, prevWidget.Data, oldOrder)

                loadData()
            }
        }
    }

    const handleMoveWidgetToNextPosition = async (widget: Widget) => {
        const widgetArrIndex = widgets.findIndex((w: Widget) => w.WidgetId === widget.WidgetId)

        if(widgetArrIndex !== -1) {
            const nextWidget: Widget = widgets[widgetArrIndex+1]

            if(nextWidget) {
                const oldOrder = parseInt(widget.Order);
                const nextOrder = parseInt(nextWidget.Order)

                //move target widget to desired location
                await editWidget(widget.WidgetId, widget.Name, widget.Data, nextOrder)

                //move the previoius widget to target's widget location
                await editWidget(nextWidget.WidgetId, nextWidget.Name, nextWidget.Data, oldOrder)

                loadData()
            }
        }
    }

    const handleEditWidget = (widget: Widget) =>{
        setEditedWidget(widget)
    }

    useEffect(() =>{
        loadData()

        window.jQuery("body").on("qm:custom:newWidget", () => {
            setIsAddNewWidgetModalShown(true)
        })
    }, [])

    if(ajaxState === AJAX_LOADING_STATUS_IDLE) {
        return null
    } else if (ajaxState === AJAX_LOADING_STATUS_ERROR) {
        return (
            <TryAgainStatement
                onTryAgainBtnClick={() =>loadData()}
                children={"There was a problem loading the dashboard widgets. Please try again."}
            />
        )
    }

    return (
        <>
            <div className="row mb-3">
                {widgets.map((widget: Widget, index) => (
                    <div className={ctx("col-lg-6 mb-4", style.widget)} key={widget.WidgetId}>
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className={ctx(style.widgetHeader, "mb-4")}>
                                    <h4 className="card-title">{widget.Name}</h4>

                                    <div className={ctx(style.widgetControl)}>
                                        <div className="dropdown d-inline-block user-dropdown">
                                            <button className={ctx("btn btn-sm btn-light waves-effect", style.widgetControlBtn)} data-bs-toggle="dropdown">
                                                <i className="ri-equalizer-line" />
                                            </button>
                                            <div className="dropdown-menu dropdown-menu-end">
                                                <a className="dropdown-item" onClick={(e) => {
                                                    e.preventDefault()

                                                    handleEditWidget(widget)
                                                }} href="#"><i className="ri-pencil-fill align-middle me-1" /> Edit</a>
                                                <div className="dropdown-divider" />
                                                <a className={ctx("dropdown-item", index === 0 && "disabled" )} onClick={(e) => {
                                                    e.preventDefault();
                                                    handleMoveWidgetToPreviousPosition(widget)
                                                }} href="#"><i className="ri-arrow-up-fill align-middle me-1" /> Move prev</a>
                                                <a className={ctx("dropdown-item", widgets.length -1 === index && "disabled" )} onClick={(e) =>{
                                                    e.preventDefault()
                                                    handleMoveWidgetToNextPosition(widget)
                                                }} href="#"><i className="ri-arrow-down-fill align-middle me-1" /> Move next</a>
                                                <div className="dropdown-divider" />
                                                <a className="dropdown-item text-danger" onClick={(e) => {
                                                    e.preventDefault();

                                                    handleDeleteWidget(widget)
                                                }} href="#"><i className="ri-close-line align-middle me-1" /> Remove</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={style.widgetBody} dangerouslySetInnerHTML={{__html: widget.Data}} />
                            </div>
                        </div>
                    </div>
                ))}
                {widgets.length > 0 && (
                    <div className={ctx("col-lg-6 mb-4", style.widget)}>
                        <div className="card mb-0">
                            <button className={"btn btn-primary"} onClick={handleOpenAddNewWidgetModal}>
                                <i className="ri-add-line" />
                                Add new widget
                            </button>
                        </div>
                    </div>
                )}
            </div>
            {isAddNewWidgetModalShown && (
                <AddNewWidgetModal
                    onClose={() => setIsAddNewWidgetModalShown(false)}
                    onSuccess={() => loadData()}
                    lastWidget={widgets[widgets.length-1]}
                />
            )}
            {!!editedWidget && (
                <EditWidgetModal widget={editedWidget} onClose={()=> setEditedWidget(null)} onSuccess={() => loadData()} />
            )}
        </>
    )
};

export default Widgets