import moment from 'moment';

export const MOMENT_TIME_FORMAT = "H:mm";
export const MOMENT_TIME_US_FORMAT = "h:mm a";
export const MOMENT_TIME_US_FORMAT_NO_MINUTES = "ha";

export const MOMENT_DATE_FORMAT = "D. M. YYYY";
export const MOMENT_DATE_US_FORMAT = "MM/DD/YY";

export const MOMENT_DATETIME_FORMAT = "D. M. YYYY, H:mm";
export const MOMENT_DATETIME_US_FORMAT = "MM/DD/YY, h:mm a";
export const MOMENT_DATETIME_FULL_US_FORMAT = "MM/DD/YY, h:mm:ss a";

export const TIME_FORMAT_FULL_ISO_8601 = "HH:mm:ss";
export const TIME_FORMAT_ISO_8601 = "HH:mm";
export const DATE_FORMAT_ISO_8601 = "YYYY-MM-DD";
export const DATE_TIME_ISO_8601 = "YYYY-MM-DD HH:mm:ss";

export const formatTimestamp = (dateString, format = MOMENT_DATETIME_FORMAT) => (
    moment.utc(dateString).format(format)
);

//date string must contain timezone, e.g. 2022-03-04T03:59:48.333721907+01:00
export const formatTimestampWithTZ = (dateString, format = MOMENT_DATETIME_FORMAT) => (
    moment(dateString).format(format)
);