import style from './TryAgainStatement.module.css';
import ctx from "classnames";

const TryAgainStatement = ({onTryAgainBtnClick, children = "There was a problem loading the data."}) => (
    <div className={"alert alert-warning"}>
        <div>{children}</div>
        <button onClick={onTryAgainBtnClick} type="button" className={ctx("btn btn-sm btn-warning waves-effect", style.btn)}>Try again</button>
    </div>
);

export default TryAgainStatement;