import {useEffect, useState} from "react";
import style from "./LostPassword.module.css";
import {requestLostPassword} from "../http";
import {setPageTitle} from "../../../library/title";

const LostPassword = () => {
    const [email, setEmail] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSuccessfullyRequested, setIsSuccessfullyRequested] = useState(null);

    useEffect(() => {
        setPageTitle("Password reset");
    }, []);

    const handleSubmit = async (e: Event) => {
        e.preventDefault();

        setIsSubmitting(true);
        const response = await requestLostPassword(email);
        setIsSubmitting(false);

        if(response){
            setIsSuccessfullyRequested(true);
        } else {
            setIsSuccessfullyRequested(false);
        }
    };

    const handleRequestAgain = () => {
        setEmail("");
        setIsSuccessfullyRequested(null);
    };

    return (
        <div>
            <div className="container-fluid p-0">
                <div className="row g-0">
                    <div className="col-lg-4">
                        <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div className="w-100">
                                <div className="row justify-content-center">
                                    <div className="col-lg-9">
                                        <div>
                                            <div className="text-center">
                                                <div>
                                                    <a href="/" className="">
                                                        <img src={require("../../../assets/img/logo.png")} />
                                                        <span className={style.logoText}>QuantMonitor</span>
                                                    </a>
                                                </div>

                                                <h4 className="font-size-18 mt-4">Reset Password</h4>
                                                <p className="text-muted">Reset your password to QuantMonitor.</p>
                                            </div>

                                            <div className="p-2 mt-5">
                                                {isSuccessfullyRequested && (
                                                    <div className="alert alert-success mb-4" role="alert">
                                                        The email instructions has been sent to {email}!
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <p>
                                                            Did not receive the email?
                                                            <button type={"button"} onClick={handleRequestAgain}>Request again</button>
                                                        </p>
                                                    </div>
                                                )}
                                                {isSuccessfullyRequested === null && (
                                                    <form className="" action="/" method={"post"} onSubmit={handleSubmit}>
                                                        <div className="auth-form-group-custom mb-4">
                                                            <i className="ri-mail-line auti-custom-input-icon" />
                                                            <label htmlFor="useremail">Email</label>
                                                            <input disabled={isSubmitting} value={email} onChange={e => setEmail(e.target.value)} type="email" required className="form-control" id="useremail"
                                                                   placeholder="Enter email" />
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <button
                                                                disabled={isSubmitting}
                                                                className="btn btn-primary w-md waves-effect waves-light"
                                                                type="submit">Reset
                                                            </button>
                                                        </div>
                                                    </form>
                                                )}
                                            </div>

                                            <div className="mt-5 text-center">
                                                <p>Already have an account? <a href="/"
                                                                              className="fw-medium text-primary">Log in</a>
                                                </p>
                                                <p>
                                                    <small>&copy; QuantMonitor.net</small>
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8">
                        <div className="authentication-bg">
                            <div className="bg-overlay" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LostPassword;