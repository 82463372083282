export const getApiUrl = (): string => {
    if(!process.env.REACT_APP_API_URL) {
        throw new Error("the API URL has not been defined!");
    }

    return process.env.REACT_APP_API_URL;
};

export const isProductionMode = (): boolean => (
    process.env.NODE_ENV === 'production'
);

export const isDebugMode = (): Boolean => !isProductionMode();

export const getTutorialVideoID = (): String => {
    if(process.env.REACT_APP_YOUTUBE_TUTORIAL_VIDEO_ID) {
        return process.env.REACT_APP_YOUTUBE_TUTORIAL_VIDEO_ID;
    }

    return "n1_NeTwHUI0";
}