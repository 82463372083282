export const MIN_LENGTH_HOST_NAME = 4;

export const validateHostName = (hostName: string) => (
    validateStartsEndsWithNumber(hostName) &&
    validateAlphaNumericalCharacters(hostName) &&
    validateMinLength(hostName)
)

//copyright https://stackoverflow.com/a/5888041/2880184
export const validateStartsEndsWithNumber = (hostName: string) => {
    if(hostName.length === 0) {
        return false
    }

    const firstLetter = hostName[0] ?? ""

    var reg = /^[\d-]+$/;

    return !reg.test(firstLetter)
}

export const validateAlphaNumericalCharacters = (hostName: string) => (
    /^[a-zA-Z0-9-]{1,}$/i.test(hostName)
)

export const validateMinLength = (hostName: string) => (
    hostName.length >= MIN_LENGTH_HOST_NAME
)