import {useContext, useEffect, useState} from 'react';

import {setPageTitle} from "../../../library/title";
import {AppContext} from "../../../context";
import {requestLostPassword} from "../../NotLoggedIn/http";
import {showErrorMessage, showSuccessMessage} from "../../../library/messages";

const Profile = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);


    const [email, setEmail] = useState("")

    useEffect(() => {
        setPageTitle("My Profile");
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsSubmitting(true);
        const response = await requestLostPassword(email);
        setIsSubmitting(false);

        if(response){
            setEmail("")
            showSuccessMessage("Please, check your e-mail inbox for further instructions!");
        } else {
            showErrorMessage("An error occurred, please, try again.");
        }
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">My Profile</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href="/">QuantMonitor</a></li>
                                <li className="breadcrumb-item active">My Profile</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Password reset</h4>
                            <p className="card-title-desc">Did you forget your password? Here you can reset it</p>

                            <form className="" action="/" method={"post"} onSubmit={handleSubmit}>
                                <div className=" mb-4">
                                    <label>Email</label>
                                    <input disabled={isSubmitting} value={email} onChange={e => setEmail(e.target.value)} type="email" required className="form-control" placeholder="Enter your email..." />
                                </div>

                                <div className="mt-4">
                                    <button disabled={isSubmitting} className="btn btn-primary w-md waves-effect waves-light" type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Profile;