import {  NavLink } from "react-router-dom";
import style from  './Sidebar.module.css'
import ctx from 'classnames'
import type {License} from "../types/licenses";

const Sidebar = ({license}: {
    license: License
}) => {
    const licenseIsActive = parseInt(license?.Status) === 1;

    const isTrial = license?.TypeName?.toLowerCase() === 'free';
    const isSubscription = !isTrial;

    return (
        <div className="vertical-menu">

            <div data-simplebar className="h-100">

                <div id="sidebar-menu">
                    <ul className={ctx("metismenu list-unstyled", style.menu)} id="side-menu">
                        <li className="menu-title">Menu</li>

                        <li>

                            <NavLink className={"waves-effect"} to="/">
                                <i className="ri-dashboard-line"></i>
                                <span>Dashboard</span>
                            </NavLink>

                            {isSubscription && licenseIsActive && (
                                <NavLink className={"waves-effect"} to="/subscription">
                                    <i className=" ri-award-line"></i>
                                    <span>Subscription</span>
                                </NavLink>
                            )}

                            {(isTrial || !licenseIsActive) && (
                                <NavLink target={"_blank"} className={"waves-effect"} to="https://quantmonitor.net/pricing">
                                    <i className=" ri-award-line"></i>
                                    <span>Subscription</span>
                                    <span className="badge rounded-pill bg-success float-end">Upgrade</span>
                                </NavLink>
                            )}
                        </li>

                        {/*<li>
                        <NavLink className={"waves-effect"} to="/profile">
                            <i className="ri-chat-1-line"></i>
                            <span>Profile</span>
                        </NavLink>
                    </li>*/}

                        <li className="menu-title">Other</li>

                        <li>
                            <a className={"waves-effect"} href="https://discord.gg/Qx2VgUxnP4" target={"_blank"}>
                                <i className="ri-discord-line"></i>
                                <span>Discord</span>
                            </a>
                        </li>

                        <li>
                            <a className={"waves-effect"} href="https://support.quantmonitor.net/" target={"_blank"}>
                                <i className="ri-chat-1-line"></i>
                                <span>Support</span>
                            </a>
                        </li>

                        <li>
                            <a className={"waves-effect"} href="https://www.quantmonitor.net/downloads" target={"_blank"}>
                                <i className="ri-chat-1-line"></i>
                                <span>Download</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;