//library
//@author @hanakivan

const collator = Intl && Intl?.Collator ? (new Intl.Collator("cs")) : null;

export const sortAlphabetically = (a, b, column = null) => {
    if(collator) {
        if(column) {
            return collator.compare(a[column], b[column]);
        }

        return collator.compare(a, b);
    }

    if(column) {
        return a[column] - b[column];
    }

    return a - b;
};