import React, {useState, useEffect, useLayoutEffect} from 'react';
import style from './Modal.module.css'
import {uuidv4} from "../../library/uuid";
import ctx from 'classnames'

const getModalSize = (size) => {
    if(size === "large") {
        return "modal-lg";
    } else if(size === "normal") {
        return "";
    } else if(size === "small") {
        return "modal-sm";
    }

    return null;
};

type Props = {
    modalTitle: String,
    children: any,
    onClose: Function,
    canCloseWithEsc?: Boolean,
    canCloseWithClickingBackdrop?: Boolean,
    onSubmitBtnClick?: Function,
    size?: "large" | "normal" | "small",
    isSubmitBtnDisabled: Boolean,
    submitBtnLabel?: String,
    canHideFooter?: Boolean,
    canHideSubmitButton?: Boolean,
};

export default class Modal extends React.PureComponent<Props> {

    static defaultProps = {
        canHideFooter: false,
        canCloseWithEsc: false,
        canCloseWithClickingBackdrop: false,
        size: "normal",
        isSubmitBtnDisabled: false,
        onSubmitBtnClick: null,
        submitBtnLabel: "Save changes",
        canHideSubmitButton: false,
    };

    uuid = `modal-${uuidv4()}`;

    componentDidMount() {
        const {canCloseWithClickingBackdrop, canCloseWithEsc} = this.props;

        // eslint-disable-next-line no-undef
        const $instance = jQuery(`#${this.uuid}`);

        $instance.modal({
            backdrop: canCloseWithClickingBackdrop ? true : "static",
            keyboard: canCloseWithEsc,
        });

        $instance.on("hidden.bs.modal", () => {
            this.props.onClose();
        })

        // eslint-disable-next-line no-undef
        $instance.modal("show")
    }

    //public
    close = () => {
        // eslint-disable-next-line no-undef
        const $instance = jQuery(`#${this.uuid}`);

        $instance.modal("hide");
    }


    render() {
        const {children, size,
            canHideFooter, modalTitle, isSubmitBtnDisabled, onSubmitBtnClick, submitBtnLabel,
            canHideSubmitButton
        } = this.props;

        return (
            <div id={this.uuid} className="modal fade" tabIndex="-1" data-keyboard={false} data-backdrop={"false"}>
                <div className={ctx("modal-dialog", getModalSize(size))}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{modalTitle}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            {children}
                        </div>
                        {!canHideFooter && (
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light waves-effect" data-bs-dismiss="modal">Close</button>
                                {!canHideSubmitButton && (
                                    <button disabled={isSubmitBtnDisabled} onClick={onSubmitBtnClick} type="button" className="btn btn-primary waves-effect waves-light">{submitBtnLabel}</button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}